import React, { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import { useI18N } from '~/contexts/NextI18NProvider';

import useWindowSize from '~/hooks/useWindowSize';

import IconLogoDarwinexZero from '~/storybook/icons/IconLogoDarwinexZero';

import {
    IconChevronRounded,
    IconDiscord,
    IconFacebook,
    IconInstagram,
    IconX,
    IconYouTube,
    BREAKPOINT_MAP,
    IconNews,
} from '@darwinex/components-library';

import { footerItem, footerItems } from '~/types';

import Nullable from '~/types/Nullable';
import styles from './Footer.module.scss';

export interface Props {
    onChangeLocale?: (newLocale: string) => void | undefined;
    localesAvailable?: string[];
    locale?: string;
    showFCAText?: Nullable<boolean>;
    showDiscordIcon?: Nullable<boolean>;
}

const Footer = ({ onChangeLocale, localesAvailable, showFCAText, showDiscordIcon }: Props) => {
    const { M, ULink, locale } = useI18N();

    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

    const { width } = useWindowSize();
    const isDesktop = width > BREAKPOINT_MAP.breakpoint_xl;

    function toggle() {
        setIsLanguageMenuOpen(!isLanguageMenuOpen);
    }

    if (!width) {
        return null;
    }

    const getDocLink = (locale: string) => {
        switch (locale) {
            case 'en':
                return 'https://www.darwinexzero.com/docs/what-is-darwinex-zero';
            case 'es':
                return 'https://www.darwinexzero.com/docs/es/que-es-darwinex-zero';
            case 'it':
                return 'https://www.darwinexzero.com/docs/it/cos-e-darwinex-zero';
            case 'de':
                return 'https://www.darwinexzero.com/docs/de/what-is-darwinex-zero';
            case 'cs':
                return 'https://www.darwinexzero.com/docs/cs/what-is-darwinex-zero';
            case 'fr':
                return 'https://www.darwinexzero.com/docs/fr/qu-est-ce-que-darwinex-zero';
            case 'zh':
                return 'https://www.darwinexzero.com/docs/zh-hant/what-is-darwinex-zero';
            default:
                return 'https://www.darwinexzero.com/docs/what-is-darwinex-zero';
        }
    };

    const footerItemsColumn1: footerItems = [
        {
            href: '/why-us#overview',
            title: M('components.footer-home.overview'),
            className: styles.footer__subitem,
        },
        {
            href: '/why-us#capital-introduction',
            title: M('components.footer-home.introduction'),
            className: styles.footer__subitem,
        },
        {
            href: '/why-us#darwinex-ecosystem',
            title: M('components.footer-home.ecosystem'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsColumn2: footerItems = [
        {
            href: '/capital-allocation#allocation-program',
            title: M('components.footer-home.overview'),
            className: styles.footer__subitem,
        },
        {
            href: '/capital-allocation#darwinia-silver',
            title: M('components.footer-home.darwinaSilver'),
            className: styles.footer__subitem,
        },
        {
            href: '/capital-allocation#darwinia-gold',
            title: M('components.footer-home.darwinaGold'),
            className: styles.footer__subitem,
        },
        {
            href: '/capital-allocation#investors',
            title: M('components.footer-home.investors'),
            className: styles.footer__subitem,
        },
        {
            href: '/capital-allocation#darwinex-pro',
            title: M('components.footer-home.darwinaPro'),
            className: styles.footer__subitem,
        },
        {
            href: '/capital-allocation#perfomance-fees',
            title: M('components.footer-home.performanceFees'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsColumn3: footerItems = [
        {
            href: '/your-darwin-index#darwin-index',
            title: M('components.footer-home.yourDarwin'),
            className: styles.footer__subitem,
        },
        {
            href: '/your-darwin-index#risk-engine',
            title: M('components.footer-home.riskEngine'),
            className: styles.footer__subitem,
        },
        {
            href: '/your-darwin-index#analytical-tool',
            title: M('components.footer-home.analyticalToolkit'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsColumn4: footerItems = [
        {
            href: '/assets#forex-cfd',
            title: M('components.footer-home.forexCfds'),
            className: styles.footer__subitem,
        },
        {
            href: '/assets#futures',
            title: M('components.footer-home.futures'),
            className: styles.footer__subitem,
        },
        {
            href: '/assets#stocks',
            title: M('components.footer-home.stocksETFs'),
            className: styles.footer__subitem,
        },
        {
            href: '/assets#accounts-platforms',
            title: M('components.footer-home.platforms'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsColumn5: footerItems = [
        {
            href: '/pricing',
            title: M('components.footer-home.subscriptionFees'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsColumn6: footerItems = [
        {
            href: '/partners',
            title: M('components.footer-home.becomePartner'),
            className: styles.footer__subitem,
        },
    ];

    const footerItemsLegal: footerItems = [
        {
            href: '/legal/terms-of-use',
            title: M('components.footer-home.websiteTerms'),
            className: styles.footer__subitem,
        },
        {
            href: '/legal/privacy-policy',
            title: M('components.footer-home.privacy'),
            className: styles.footer__subitem,
        },
        {
            href: '/legal/cookie-policy',
            title: M('components.footer-home.cookies'),
            className: styles.footer__subitem,
        },
        {
            href: '/legal/refund-policy',
            title: M('components.footer-home.refund'),
            className: styles.footer__subitem,
        },
    ];

    return (
        <footer className={styles.footer}>
            <div className={classNames(styles.footer__container, 'dxcl-container')}>
                <div className={classNames('dxcl-row')}>
                    <div className={classNames(styles.footer__wrapper)}>
                        <Link href="/" className={styles.footer__logo_container}>
                            <IconLogoDarwinexZero className={styles.footer__logo} />
                        </Link>
                        <div>
                            <p className={styles.footer__text}>
                                <a
                                    href="mailto:info@darwinexzero.com "
                                    className={styles.footer__link}
                                    title={M('components.footer-home.mail') as string}
                                >
                                    {M('components.footer-home.mail')}
                                </a>
                            </p>
                            <p className={styles.footer__text}>{M('components.footer-home.address')}</p>
                        </div>
                    </div>
                    <div className={classNames(styles.footer__wrapper)}>
                        <ul className={styles.footer__list}>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.zero')}</p>
                                ) : (
                                    <ULink href="/why-us" className={styles.footer__item}>
                                        {M('components.footer-home.zero')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn1.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>
                                        {M('components.footer-home.capitalAllocation')}
                                    </p>
                                ) : (
                                    <ULink href="/capital-allocation" className={styles.footer__item}>
                                        {M('components.footer-home.capitalAllocation')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn2.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.yourIndex')}</p>
                                ) : (
                                    <ULink href="/your-darwin-index" className={styles.footer__item}>
                                        {M('components.footer-home.yourIndex')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn3.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.assets')}</p>
                                ) : (
                                    <ULink href="/assets" className={styles.footer__item}>
                                        {M('components.footer-home.assets')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn4.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.pricing')}</p>
                                ) : (
                                    <ULink href="/pricing" className={styles.footer__item}>
                                        {M('components.footer-home.pricing')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn5.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.partners')}</p>
                                ) : (
                                    <ULink href="/partners" className={styles.footer__item}>
                                        {M('components.footer-home.partners')}
                                    </ULink>
                                )}
                                {isDesktop && (
                                    <ul className={styles.footer__sublist}>
                                        {footerItemsColumn6.map(({ href, title, className }: footerItem) => (
                                            <li className={className}>
                                                <ULink href={href} className={className}>
                                                    {title}
                                                </ULink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className={styles.footer__item}>
                                {isDesktop ? (
                                    <p className={styles.footer__item}>{M('components.footer-home.support')}</p>
                                ) : (
                                    <ULink href="/support" className={styles.footer__item}>
                                        {M('components.footer-home.support')}
                                    </ULink>
                                )}
                                {isDesktop && locale && (
                                    <ul className={styles.footer__sublist}>
                                        <li className={styles.footer__item}>
                                            <ULink href="/support#contact" className={styles.footer__item}>
                                                {M('components.footer-home.contact')}
                                            </ULink>
                                            <ULink href="/support#faqs" className={styles.footer__item}>
                                                {M('components.footer-home.faqs')}
                                            </ULink>
                                            <a href={getDocLink(locale)} target="_blank" rel="noopener noreferrer">
                                                {M('components.footer-home.knowledge-base')}
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className={classNames(styles.footer__wrapper)}>
                        <ul className={classNames(styles.footer__list, styles.footer__legal_list)}>
                            {footerItemsLegal.map(({ href, title, className }: footerItem) => (
                                <li className={className}>
                                    <ULink href={href} className={className}>
                                        {title}
                                    </ULink>
                                </li>
                            ))}
                        </ul>
                        <div className={classNames(styles.footer__wrapper_social)}>
                            <div className={styles.footer__language_list}>
                                {locale && (
                                    <button
                                        type="button"
                                        className={classNames(styles.footer__button, {
                                            [`${styles.footer__active}`]: isLanguageMenuOpen,
                                        })}
                                        aria-label="language"
                                        onClick={toggle}
                                    >
                                        {M(`components.footer-home.${locale}`)}
                                        <IconChevronRounded className={styles.footer__chevron} />
                                    </button>
                                )}
                                {localesAvailable && onChangeLocale && (
                                    <ul className={styles.footer__flags}>
                                        {localesAvailable.map((lang) => (
                                            <li className={styles.footer__languageItem} key={lang}>
                                                <div
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onChangeLocale(lang);
                                                        toggle();
                                                    }}
                                                >
                                                    {M(`components.footer-home.${lang}`)}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <ul className={styles.footer__social_list}>
                                {showDiscordIcon && (
                                    <Link
                                        href="https://discord.com/invite/A5NjGduWbp"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <IconDiscord className={styles.footer__icon} width={24} height={24} />
                                    </Link>
                                )}
                                <Link
                                    href="https://www.instagram.com/darwinexzero/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <IconInstagram className={styles.footer__icon} width={24} height={24} />
                                </Link>
                                <Link
                                    href="https://www.youtube.com/@DarwinexZero/featured"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <IconYouTube className={styles.footer__icon} width={24} height={24} />
                                </Link>
                                <Link href="https://twitter.com/DarwinexZero" rel="noopener noreferrer" target="_blank">
                                    <IconX className={styles.footer__icon} width={24} height={24} />
                                </Link>
                                <Link
                                    href={
                                        locale === 'cs'
                                            ? 'https://www.facebook.com/DarwinexZeroCZSK/'
                                            : 'https://www.facebook.com/darwinexzero/'
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <IconFacebook className={styles.footer__icon} width={24} height={24} />
                                </Link>
                                <Link href="https://blog.darwinexzero.com/" rel="noopener noreferrer" target="_blank">
                                    <IconNews className={styles.footer__icon} width={24} height={24} />
                                </Link>
                            </ul>
                        </div>
                    </div>
                    <div className={classNames(styles.footer__wrapper, `flex-lg-column`)}>
                        <p className={styles.footer__copy}>{M('components.footer-home.copyright')}</p>
                        {showFCAText && <p className={styles.footer__copy}>{M('components.footer-home.FCA')}</p>}
                        <p className={styles.footer__copy}>{M('components.footer-home.copyrightText')}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
